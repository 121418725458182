/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, TitleMain, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Subtitle, Title, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--02 pl--16 pr--16 pt--02" anim={null} menu={true} animS={null} style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box mb--0 mt--0 pb--0 pr--12 pt--0" style={{"maxWidth":90}}>
                
                <Image alt={""} src={"https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=860x_fo_.png"} svg={false} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=350x_fo_.png 350w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=660x_fo_.png 660w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=860x_fo_.png 860w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=1400x_fo_.png 1400w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=2000x_fo_.png 2000w"} content={null}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper className="menu-logo-box mt--02">
                
                <TitleMain className="title-box fs--22 w--600 pl--0" content={"<span style=\"color: rgb(0, 44, 91);\">Půjčovna minibagrů</span>"}>
                </TitleMain>

                <Text className="text-box fs--14 w--600 swpf--uppercase ls--02 lh--16 mt--0 pl--0" content={"<span style=\"color: rgb(225, 72, 47);\">Plzeň a okolí</span>"}>
                </Text>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#cenik"} tsbtn={null} target={""} content={"ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#technika"} tsbtn={null} target={""} content={"naše technika"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#kontakt"} tsbtn={null} target={""} content={"kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn4 btn-box--shape5 btn-box--cColor2 fs--16 w--600 swpf--uppercase ls--01 pl--06 pr--0" innerClassName="" href={"#poptavka"} tsbtn={null} target={""} content={"Poptávka"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 pl--06 pr--06" innerClassName="" href={"#sluzby-2"} tsbtn={null} target={""} content={"momentálně nedostupné"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-17c25jl pb--0 pt--80" name={"uvod"} style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null} parallax={false} css={css`
      
    background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/11212/d8a66c2dc2604252b02ce3d5577d57a4_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5 --center pb--20 pt--10" anim={"7"} animS={"5"} style={{"maxWidth":1360}}>
              
              <Subtitle className="subtitle-box ff--3 fs--26 w--500 subtitle-box--invert ls--001 mt--16" content={"Webové stránky na prodej – info@tvorba-stranek-brno.cz"}>
              </Subtitle>

              <Title className="title-box fs--72 w--600 title-box--invert ls--004" content={"Zapůjčte si&nbsp;<span style=\"background-color: rgb(0, 44, 91);\">&nbsp;kvalitu&nbsp;<br></span>a&nbsp;<span style=\"background-color: rgb(0, 44, 91);\">&nbsp;spolehlivost.&nbsp;</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--26 w--500 subtitle-box--invert ls--001 mt--16" content={"SLUŽBY POZASTAVENY<br>Minibagry již od 1200 Kč / den"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn5 btn-box--shape5 btn-box--cColor2 fs--20 w--600 swpf--uppercase mt--25" href={"#technika"} content={"Naše technika"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape2 --shadow4 --center el--3 pb--0 pl--40 pr--40 pt--30" anim={null} style={{"maxWidth":"","backgroundColor":"rgba(0, 44, 91, 1)"}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka půjčovna minibagru"} src={"https://cdn.swbpg.com/o/11212/b1681fd35e8b4a1cb49a5d714c5485e4.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} content={null} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--invert mt--08" content={"Stroje v perfektní kondici"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(193, 215, 235);\">Půjčujeme minibagry pouze nové nebo zánovní, které pravidelně servisujeme.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka minibagr bez skrytých poplatků"} src={"https://cdn.swbpg.com/o/11212/e2b74a73b46c45cda6f70a09312c0cd1.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} content={null} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--invert mt--08" content={"Bez kaucí a skrytých poplatků"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(193, 215, 235);\">Finální cenu obdržíte před vyzvednutím stroje, zašleme Vám rezervační sms.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka přeprava minibagru"} src={"https://cdn.swbpg.com/o/11212/248d6af46c2d4944aa18f4ef3b95b1fd.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} content={null} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--invert mt--08" content={"Vlastní přepravní<br>vlek"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(193, 215, 235);\">S každým strojem dostanete vlastní přepravní vlek – nic není potřeba překládat.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--0" name={"technika"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --shape2 --shadow5 --center el--3 pb--0 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(0, 44, 91, 1)"}} columns={"3"} fullscreen={false}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--60" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--left pb--10 pt--10" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48" content={"Naše technika<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--40" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Půjčovna minibagrů – vrtací násada"} src={"https://cdn.swbpg.com/t/11212/d7d3f5bb66644b9aaf850f35d5e341a6_e=1x373x1778x1335_s=860x_.jpeg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d7d3f5bb66644b9aaf850f35d5e341a6_e=1x373x1778x1335_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/d7d3f5bb66644b9aaf850f35d5e341a6_e=1x373x1778x1335_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/d7d3f5bb66644b9aaf850f35d5e341a6_e=1x373x1778x1335_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/d7d3f5bb66644b9aaf850f35d5e341a6_e=1x373x1778x1335_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Subtitle className="subtitle-box fs--18 pl--0" content={"Vrtací jednotky"}>
              </Subtitle>

              <Title className="title-box title-box--style3 fs--36 swpf--uppercase mt--06 pl--0" content={"&nbsp;Augetorque&nbsp;"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Půjčovna minibagrů – Kubota"} src={"https://cdn.swbpg.com/t/11212/d2e4a57ed75145ac825d5ccdf5055b1c_e=48x429x1298x974_s=660x_.jpeg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d2e4a57ed75145ac825d5ccdf5055b1c_e=48x429x1298x974_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/d2e4a57ed75145ac825d5ccdf5055b1c_e=48x429x1298x974_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/d2e4a57ed75145ac825d5ccdf5055b1c_e=48x429x1298x974_s=860x_.jpeg 860w"} position={null}>
              </Image>

              <Subtitle className="subtitle-box fs--18 pl--0" content={"Minibagry"}>
              </Subtitle>

              <Title className="title-box title-box--style3 fs--36 swpf--uppercase mt--06 pl--0" content={"&nbsp;Kubota&nbsp;"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Půjčovna minidumperů"} src={"https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=190x0x2178x1633_s=860x_.jpeg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=190x0x2178x1633_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=190x0x2178x1633_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=190x0x2178x1633_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=190x0x2178x1633_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=190x0x2178x1633_s=2000x_.jpeg 2000w"} position={{"x":"-43.89592760180995%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box fs--18 pl--0" content={"Minidumpery"}>
              </Subtitle>

              <Title className="title-box title-box--style3 fs--36 swpf--uppercase mt--06 pl--0" content={"&nbsp;Cormidi&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"} style={{"backgroundColor":"rgba(250, 251, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --justify el--2 pb--20 pt--20 flex--center" style={{"maxWidth":1470}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5" anim={"5"} animS={"5"} style={{"maxWidth":528}}>
              
              <Subtitle className="subtitle-box" content={"Půjčujeme minibagry již od"}>
              </Subtitle>

              <Title className="title-box fs--72 ls--004 mt--02" content={" 1200 Kč / den."}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":528}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--sbtn1 btn-box--shape5 btn-box--cColor2 btn-box--right fs--20 w--600 swpf--uppercase ls--01 pb--0 pt--0" innerClassName="" href={"#poptavka"} content={"Nezávazná poptávka"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-kfbg4k --left --parallax pb--60 pt--60" name={"9yxso1n5lt8"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l3"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/ca06bd86eeb04b25a4a2a2c5781d6d1f_ove=0a1328x87__s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--25 pt--25" style={{"maxWidth":1470}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Vše co potřebujete"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Lopaty 20 cm, 40 cm a 80 cm jsou již v základní ceně půjčovného. Nic nečepujete, stroje mají rychloupínač a výměna lopaty je otázkou jedné minuty.\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"All-inclusive"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Poskytujeme all-inclusive službu, přijedete, připojíme vlek a Vy můžete vyrazit. Nemusíte se o nic starat, vše co potřebujete k práci je součástí  připojené soupravy.\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Příslušenství"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Půjčíme Vám i  příslušenství k bagru, které jinde nenajdete. Vrtací  jednotku, hrábě, speciální lžíce, rovnací lištu, naklápěcí hydrosvahovky apod. Vše Vám urychlí a zpříjemní práci.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"poptavka"} style={{"backgroundColor":"rgba(0,44,91,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--12 pt--12">
              
              <Title className="title-box title-box--invert" content={"Poptávka"}>
              </Title>

              <Text className="text-box mt--12" style={{"maxWidth":740}} content={"<span style=\"color: rgb(193, 215, 235);\">Vyplňte nasledující formulář a my Vás budeme brzy kontaktovat.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 mt--12" anim={"2"} animS={"5"}>
              
              <ContactForm className="--shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Požadovaná technika","type":"textarea","required":true,"placeholder":"Jakou techniku poptáváte?"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(250, 251, 255, 1)"}} layout={"l1"} parallax={false}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26" content={"Půjčovna minibagrů"}>
              </Title>

              <Text className="text-box fs--16 w--600 swpf--uppercase ls--02 lh--16 mt--0" content={"<span style=\"color: rgb(225, 72, 47);\">Plzeň a okolí</span>"}>
              </Text>

              <Text className="text-box w--500 mt--16" content={"<span style=\"color: rgb(0, 44, 91);\">Plzeň, Česká Republika</span><span style=\"color: rgb(0, 44, 91);\"><br>podpora@saywebpage.com<br></span>"}>
              </Text>

              <Text className="text-box fs--13 w--500 mt--16" content={"partneři: <a href=\"https://alu-dvere.cz/\">hliníkové vchodové dveře</a> ,&nbsp;&nbsp;<a href=\"https://cistyfiltr.cz/\">čištění filtru pevných částic</a>, <a href=\"https://pujcovna-dodavek-ostravsko.cz/\">půjčovna dodávek ostrava</a>&nbsp; ,&nbsp; <a href=\"https://cistymotor.cz/\">čištění motoru</a>&nbsp;, <a href=\"https://cnc-obrabeni-dreva.cz/\">frézování dřeva</a>, <a href=\"https://nabytek-na-miru-brno.cz/kuchyne-na-miru-brno\">kuchyně na míru Brno</a><span style=\"color: rgb(0, 44, 91);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0">
              
              <Text className="text-box text-box--center w--500 lh--16" style={{"maxWidth":361}} content={"<span style=\"color: rgb(0, 44, 91);\">Půjčujeme bez kauce a papírování špičková minirypadla za bezkonkurenční ceny pro svépomocnou realizaci výkopových prací. Chcete rychle, levně a a bez komplikací provést jakýkoliv výkop? Kontaktujte nás a na všem se domluvíme.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#cenik"} content={"Ceník"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#technika"} content={"Naše technika"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#poptavka"} content={"Poptávka"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#poptavka"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}